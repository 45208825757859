import React from 'react';
import {  Route,Redirect } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Login from './pages/Login';
import Home from './pages/Home'
import NovoMotorista from './pages/NovoMotorista'
import Promocao from './pages/Promocao'
import Parceiro from './pages/Parceiro'
import Cupons from './pages/Cupons'
import Ajuda from './pages/Ajuda'
import IndicarParceiro from './pages/IndicarParceiro'
import Sorteio from './pages/Sorteio'
import Perfil from './pages/Perfil'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route path="/login" component={Login} exact={true} />
      <Route path="/novo" component={NovoMotorista} exact={true} />
      <Route path="/home" component={Home} exact={true} />
      <Route path="/promocao/:idPromocao" component={Promocao} exact={true} />
      <Route path="/parceiro/:idParceiro/:idPromocao" component={Parceiro} exact={true} /> 
      <Route path="/cupons" component={Cupons} exact={true} /> 
      <Route path="/parceiros" component={Cupons} exact={true} />
      <Route path="/ajuda" component={Ajuda} exact={true} /> 
      <Route path="/indicar" component={IndicarParceiro} exact={true} /> 
      <Route path="/sorteio" component={Sorteio} exact={true} />
      <Route path="/perfil" component={Perfil} exact={true} /> 
    </IonReactRouter>
  </IonApp>
);

export default App;
