
import axios,{AxiosRequestConfig} from 'axios'

import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

//https://api.soganhei.com.br

const http = axios.create({
    baseURL:"https://api.soganhei.com.br",
    timeout: 180000,
})

async function getToken(http:AxiosRequestConfig){

    const { value } = await Storage.get({ key: 'soganheiTokenApp' });
    
    if(value != null){
        http.headers.common['Authorization'] = `Bearer ${value}`;
        http.headers.common['X-App'] = "soganhei";
    } 
    return http

}

http.interceptors.request.use(function (config) {
     
    return  getToken(config) ;

  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

export default http