import {
  IonContent,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonPage, 
  IonList,
  IonCheckbox,   
  IonButton,
  IonLoading, 
  IonAlert,
  IonText
} from '@ionic/react';
 
import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom'

import moment from 'moment'
import {toMoney} from 'vanilla-masker'



import http from '../http'


import { Promocao as Payload } from '../Soganhei'

import './Promocao.css'

import Menu from '../components/Menu'


const Promocao: React.FC<RouteComponentProps> = (props) => {

  const [payload, setPyload] = useState<Payload>()   
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { idPromocao } = useParams()
  
  useEffect(() => {
    
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    http.get(`/motorista/promocoes/${idPromocao}`, config).then(response => {
      setPyload(response.data)
    }).catch(error => {
      console.error(error.response)
    })

  }, [idPromocao])

  function novoCupom() {

    setShowLoading(true)
 
     
    const data = {
      idParceiro: payload?.idParceiro,
      idServico: payload?.idServico,
      idPromocao: payload?.idPromocao,   
      porcentagem: payload?.porcentagem, 
      valorServico: payload?.valorServico, 
      valorComDesconto: payload?.valorComDesconto, 
      timezoneMotorista: moment().format(),
    }
   
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    http.post(`/motorista/cupons`,data, config).then(response => {

      setShowLoading(false)
      setShowAlert(true)

    }).catch(error => {
      console.error(error.response)
    })

  }

  return (

    <IonPage>
      <Menu route={props} nomeMenu="Desconto" /> 
      <IonContent>
        <IonItem lines="none" detail style={{ marginTop: 30 }} onClick={() => { props.history.push(`/parceiro/${payload?.idParceiro}/${payload?.idPromocao}`) }}>
          <IonThumbnail>
            <img src={`https://soganhei.s3.amazonaws.com/logos/${payload?.logoParceiro}`} alt="Logo empresa" />
          </IonThumbnail>
          <IonLabel style={{ paddingLeft: 20 }}>{payload?.nomeParceiro}</IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>
            {payload?.nomeServico}
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>
            Endereço
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>
            {payload?.enderecoParceiro?.rua} , número {payload?.enderecoParceiro?.numero} <br /> {payload?.enderecoParceiro?.bairro} {payload?.enderecoParceiro?.cidade} {payload?.enderecoParceiro?.uf}
            <br /> {payload?.enderecoParceiro?.cep}
          </IonLabel>
        </IonItem>

        <IonItem lines="none">
          <IonLabel>
           de <i style={{textDecoration:"line-through"}}>R$ {toMoney(`${payload?.valorServico}`)}</i>
          </IonLabel>           
          <IonLabel style={{fontSize:26,textAlign:"right"}}>
          R$ {toMoney(`${payload?.valorComDesconto}`)}
          </IonLabel>
        </IonItem>

        <IonItem lines="none">
          <IonLabel>
            Forma de pagamento: Dinheiro e cartão
          </IonLabel>
        </IonItem>
         
        <IonItem lines="none">
          <IonLabel>
            Observações
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonText>
            {payload?.observacoesParceiro}
          </IonText>
        </IonItem>

        <div style={{ padding: 15 }}>
          <IonButton className="btnSalvarCupom" onClick={() => novoCupom()} expand="full">RESERVAR</IonButton>
        </div>

        <IonLoading          
          isOpen={showLoading}
          onDidDismiss={() => { }}
          message={'Aguarde...'}
        />

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={(e) => setShowAlert(false) }         
          header={ "Cupom reservado com sucesso" }           
          message={`Agende seu horário agora mesmo!`}
          buttons={[
                {
                  text: 'Agendar',                                 
                  handler: () => {
                    window.open(`https://wa.me/55${payload?.whatsAppParceiro}?text=Ol%C3%A1!+Te+achei+no+App+S%C3%B3Ganhei`, '_system')
                  }
                },
                {
                  text: 'Ver Cupom',                 
                  handler: () => {
                       props.history.push("/cupons")
                  }
                }
          ]}
        />

      </IonContent>
    </IonPage>

  );
};

export default Promocao;