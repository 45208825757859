import { IonContent, 
   IonPage, 
   IonItem,  
   IonLabel, 
   IonInput, 
   IonButton, 
   IonAlert } from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'

 
import Menu from '../components/Menu'

import http from '../http'


import './Perfil.css';


import {Motorista} from '../Soganhei'


const Perfil: React.FC<RouteComponentProps> = (props) => {

  const [payload, setPayload] = useState<Motorista>();  
  const [showAlert, setShowAlert] = useState(false);

  const [nomeMotorista, setNomeMotorista] = useState("");  
  const [telefone, setTelefone] = useState("");     
  const [senha, setSenha] = useState("");  
   
   
  useEffect(() => {
    
    const config = {        
        headers:{
        "Content-Type":"application/json"
        }
      }

      http.get("/motorista/perfil",config).then(response=>{
        
        setNomeMotorista(response.data.nomeMotorista)
        setTelefone(response.data.telefone)
        setPayload(response.data)


      }).catch(error=>{
          console.error(error.response)
      })

  }, [])


  function atualizarPerfil(){

    const config = {        
      headers:{
      "Content-Type":"application/json"
      }
    }
 
    const payload = {
      nomeMotorista, 
      telefone: parseInt(`${telefone}`), 
      senha, 
    }

    http.put("/motorista/perfil",payload, config).then(response=>{
      setShowAlert(true)      
    }).catch(error=>{
        console.error(error.response)
    })


  }

 


  return (

    <IonPage>
      <Menu route={props} nomeMenu="Meus dados" /> 
      <IonContent>

            <IonItem className="itemPerfil" lines="none"> 
              <IonLabel position="floating">Nome</IonLabel>
              <IonInput value={nomeMotorista} className="inputPerfil"   onIonChange={e => { setNomeMotorista(e.detail.value!) } }></IonInput>
            </IonItem> 
            <IonItem className="itemPerfil"  lines="none">            
              <IonInput className="inputPerfil" disabled  value={payload?.email}></IonInput>
            </IonItem> 
            <IonItem  className="itemPerfil" lines="none"> 
              <IonLabel position="floating">Telefone</IonLabel>
              <IonInput className="inputPerfil" type="tel" value={telefone}   onIonChange={e => { setTelefone(e.detail.value!) } }></IonInput>
            </IonItem> 
            <IonItem className="itemPerfil"  lines="none">     
            <IonLabel position="floating">Nova senha</IonLabel>        
              <IonInput className="inputPerfil" type="password" value={senha} onIonChange={e => { setSenha(e.detail.value!) } }></IonInput>
            </IonItem> 
            <IonButton className="btnSalvarCupom" style={{marginTop:50}} onClick={() => atualizarPerfil() } expand="full">SALVAR</IonButton>

            <IonAlert
            isOpen={showAlert}
            onDidDismiss={(e) => setShowAlert(false) }         
            header={ "Salvo com sucesso" }           
            message={``}
            buttons={["OK"]}
          />

      </IonContent>
    </IonPage>
  );
};

export default Perfil;
