

function NomeMes(data:string):string{

    var mes:string[] = data.split("-")

    var meses= ["","janeiro","fevereiro","marco","abril","maio","junho","julho","agosto","septembro","outubro","novembro","dezembro"]

    var m:number = parseInt(mes[1])
    return `${mes[2]} ${meses[m]} de ${mes[0]}`
}

export default NomeMes