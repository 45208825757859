import {
    IonContent, 
    IonPage,  
    IonLoading, 
    IonAlert, 
    IonItem, 
    IonLabel, 
    IonInput, 
    IonButton

  } from '@ionic/react';
   
  import React, { useState } from 'react';
  import { RouteComponentProps } from 'react-router-dom'
  
   
  
  import http from '../http'
  
  
   
  import './Sorteio.css'
   
  const Sorteio: React.FC<RouteComponentProps> = (props) => {
  
    const [codigoSorteio, setCodigoSorteio] = useState("")
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
      
    function salvarCodigo(){

      setShowLoading(true)
      
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      }

      const data = {
        codigoSorteio,
      }
  
      http.post(`/motorista/sorteio`,data, config).then(response => {
        
        setShowLoading(false)
        setShowAlert(true)

      }).catch(error => {
        console.error(error.response)

        setShowLoading(false)
      })

    }
   
  
    return (
  
      <IonPage>
         
        <IonContent className="contentSorteio">
           
            <IonItem className="sorteioItem imgLogoSorteio" lines="none">
              <img src="https://soganhei.com.br/images/logo.png" alt="Logo SóGanhei"/> 
            </IonItem>
            <IonItem className="sorteioItem" lines="none">
              <IonLabel className="labelSorteio">Digite o código do sorteio</IonLabel>
            </IonItem>
            <IonItem className="sorteioItem" lines="none">
              <IonInput value={codigoSorteio} className="inputSorteio" onIonChange={e => setCodigoSorteio(e.detail.value!)}></IonInput>
            </IonItem> 
            <IonItem className="sorteioItem" lines="none">
              <IonButton className="btnSorteio" style={{marginTop:20}} onClick={() => salvarCodigo() } expand="full">SALVAR</IonButton>
            </IonItem>
          
          <IonLoading          
            isOpen={showLoading}
            onDidDismiss={() => { }}
            message={'Aguarde...'}
          />
  
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={(e) => setShowAlert(false) }         
            header={ "Sucesso!" }           
            message={`Agora você está participando do sorteio!`}
            buttons={[ 
                  {
                    text: 'Ver Promoções',                 
                    handler: () => {
                         props.history.push("/home")
                    }
                  }
            ]}
          />
  
        </IonContent>
      </IonPage>
  
    );
  };
  
  export default Sorteio;
  