import {
  IonContent,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonPage,
  IonNote,
  IonList,      
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { RouteComponentProps,useParams } from 'react-router-dom'

import moment from "moment"

import { Parceiro, Promocoes } from '../Soganhei'

import './Promocao.css'

import http from '../http'

import Menu from '../components/Menu'

import NomeMes from '../utils/nomeMes'


const ParceiroPage: React.FC<RouteComponentProps> = (props) => {
 
  const [items, setItems] = useState<Parceiro>();

  const {idParceiro} = useParams()

  useEffect(()=>{

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    http.get(`/motorista/parceiros/${idParceiro}`, config).then(response => {
      setItems(response.data)
    }).catch(error => {
      console.error(error.response)
    })
       

  },[idParceiro])

  function ItemPromocao(item: Promocoes) {

    const urlPromocao = `/promocao/${item.idPromocao}`

    const date = moment(item.dataFinal).format("YYYY-MM-DD")
    const dataFinal:string   = NomeMes(date)

      return (
        <IonItem onClick={() => props.history.push(urlPromocao)} detail>
          <IonNote style={{ fontSize: 25, paddingRight: 10, marginTop:20 }}>{item.porcentagem}%</IonNote>
          <IonLabel>
            <h2>{item.nomeServico}</h2>
            <p>Acaba {dataFinal}</p>
          </IonLabel>
        </IonItem>
      )
    }

  
  return (

    <IonPage>
      <Menu route={props} nomeMenu="Parceiro" /> 
      <IonContent>
        <IonItem lines="none" style={{ marginTop: 30 }}>
          <IonThumbnail>
          <img src={`https://soganhei.s3.amazonaws.com/logos/${items?.logoParceiro}`} alt="Logo empresa" />
          </IonThumbnail>
          <IonLabel style={{ paddingLeft: 20 }}>{items?.nomeParceiro}</IonLabel>
        </IonItem>
       
        <IonItem lines="none">
          <IonLabel>
            Endereço
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>
          {items?.endereco.rua} , número {items?.endereco.numero} <br/> {items?.endereco.bairro} {items?.endereco.cidade} {items?.endereco.uf}
          <br/> {items?.endereco.cep} 
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel>
            Promoções
          </IonLabel>
        </IonItem>
          <IonList>
          {items?.promocoes.map((data, idx) => <ItemPromocao {...data} key={idx} />)}
          </IonList>   
      </IonContent>
    </IonPage>

  );
};

export default ParceiroPage;
