import { IonContent, IonInput, IonItem, IonLabel, IonPage, IonHeader, IonToolbar, IonButton, IonIcon, IonLoading, IonAlert } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';


import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'

import { Plugins } from '@capacitor/core';

import {toPattern} from 'vanilla-masker'

import './Login.css';

import http from '../http'

interface payload {
  nomeMotorista?:string,
  email?:string,
  telefone?: number,
  senha?:string,
}


const Login: React.FC<RouteComponentProps> = ({ history }) => {

  const [state, setState] = useState<payload>({})
  const [telefone, setTelefone] = useState("")   
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
   

  useEffect(() => {
    http.get("/auth/ping").then(response => { })
  }, [])

  const { Storage } = Plugins;

  async function setStorage(token: string) {
    await Storage.set({
      key: 'soganheiTokenApp',
      value: token,
    });
  }

  function novoMotorista() {

    const t:any = `${telefone}`.match(/\d+/g)?.join("")
 
    const payload = {...state,telefone:parseInt(t)}
     
    const config = {
      headers: {
        "Content-Type": "application/json", 
        "X-App":"soganhei"
      }
    }

    http.post("/motorista/novo", payload, config).then(response => {

      const { token } = response.data

      setShowLoading(false)

      setStorage(token)
      history.push(`/home`)


    }).catch(error => {

      setShowLoading(false)
      setShowAlert(true)
    })
  }

  function formatPhone(phone: string){
    setTelefone(toPattern(phone,"(99) 99999-9999"))
  }

  function onChange(state:object){
      setState(state)
  }


  return (

    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonLabel style={{ fontSize: 25, marginLeft: 15 }} ><IonIcon onClick={() => history.push("/login")} size="14" slot="icon-only" icon={arrowBackOutline} /></IonLabel>
        </IonToolbar>
      </IonHeader>
      <IonContent className="login">
        <IonItem lines="none" style={{ textAlign: "center" }} className="itemInput">
          <IonLabel color="light">Novo Motorista</IonLabel>
        </IonItem>
        <div id="form" style={{marginTop:50}}>
          <form>
            <IonItem className="itemInput">
              <IonLabel position="floating">Nome e Sobrenome</IonLabel>
              <IonInput value={state?.nomeMotorista}  onIonChange={e=> setState({...state,nomeMotorista:e.detail.value!}) }></IonInput>
            </IonItem>
            <IonItem className="itemInput">
              <IonLabel position="floating">Telefone</IonLabel>
              <IonInput type="tel" value={telefone} onIonChange={e => formatPhone(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem className="itemInput">
              <IonLabel position="floating">Email</IonLabel>
              <IonInput type="email" name="email" value={state?.email} onIonChange={e=> setState({...state,email:e.detail.value!}) }></IonInput>
            </IonItem>
            <IonItem className="itemInput">
              <IonLabel position="floating">Senha</IonLabel>
              <IonInput type="password" value={state?.senha} onIonChange={e => setState({...state,senha:e.detail.value!}) }></IonInput>
            </IonItem>
            <br />
            <IonButton className="btnPrimary" expand="block" onClick={() => novoMotorista()}>Cadastrar</IonButton>

             

          </form>
        </div>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => { }}
          message={'Aguarde...'}
        />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={(e) => setShowAlert(false)}
          header={"Error para cadastrar!"}
          message={``}
          buttons={["OK"]}
        />
      </IonContent>
    </IonPage>

  );
};

export default Login;
