import { IonContent, IonList, IonItem, IonLabel, IonPage,  IonNote,   IonSearchbar, } from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'

import moment from 'moment'

import Menu from '../components/Menu'

import http from '../http'


import './Home.css';

import { Promocoes } from '../Soganhei'

import NomeMes from '../utils/nomeMes'


const Home: React.FC<RouteComponentProps> = (props) => {

  const [searchText, setSearchText] = useState('');  
  const [items, setItems] = useState<Promocoes[]>([]);


  function promocoes(params:Object){

        const config = {
          params, 
          headers:{
          "Content-Type":"application/json"
          }
        }

        http.get("/motorista/promocoes",config).then(response=>{
          setItems(response.data)
        }).catch(error=>{
            console.error(error.response)
        })

  }
   
  useEffect(() => {
    promocoes({})
  }, [])

  moment.locale('pt-br');

  function Promocao(item: Promocoes) {

    const urlPromocao:string = `/promocao/${item.idPromocao}`
    
    const date = moment(item.dataFinal).format("YYYY-MM-DD")
    const dataFinal:string   = NomeMes(date)
 
    return (
      <IonItem onClick={() => props.history.push(urlPromocao)} detail>
        <IonNote style={{ fontSize: 35, paddingRight: 10, marginTop:20,   }}>{item.porcentagem}%</IonNote>
        <IonLabel>
          <h2>{item.nomeServico}</h2>
          <h3>{item.nomeParceiro}</h3>
          <p>Acaba {dataFinal}</p>
        </IonLabel>
      </IonItem>
    )
  }

  function buscarPromocao(nomeServico:string){
     
     setSearchText(nomeServico)

     promocoes({
      nomeServico, 
     })

  }


  return (

    <IonPage>
      <Menu route={props} nomeMenu="Descontos" /> 
      <IonContent>
        <IonSearchbar value={searchText} placeholder="Buscar serviços" onIonChange={(e) => buscarPromocao(e.detail.value!)  }></IonSearchbar>
        <IonList>
          {items.map((data, idx) => <Promocao {...data} key={idx} />)}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Home;