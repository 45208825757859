import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonNote,
  IonList,
  IonSearchbar,
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'

import {toMoney} from 'vanilla-masker'

import Menu from '../components/Menu'
import http from '../http'


import { Cupom } from '../Soganhei'

import moment from 'moment'


import "./Cupons.css"

import NomeMes from '../utils/nomeMes'


const CuponsPage: React.FC<RouteComponentProps> = (props) => {

  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState<Cupom[]>([]);
  const [showModal, setShowModal] = useState(false)
  const [bodyCupom, setBodyCupom] = useState<Cupom>()


  function Cupons(params: Object) {

    const config = {
      params,
      headers: {
        "Content-Type": "application/json"
      }
    }

    http.get("/motorista/cupons", config).then(response => {
      setItems(response.data)
    }).catch(error => {
      console.error(error.response)
    })

  }

  useEffect(() => {
    Cupons({})
  }, [])

  function buscarCupom(nomeServico: string) {

    setSearchText(nomeServico)

    Cupons({
      nomeServico,
    })

  }

  function verCupom(item: Cupom) {

    const date = moment(item.timezoneMotorista).format("YYYY-MM-DD")
    const timezoneMotorista:string   = NomeMes(date)

    const data = {
      ...item, 
      timezoneMotorista,
      endereco: JSON.parse(`${item.enderecoParceiro}`),  
    }

    setBodyCupom(data)
    setShowModal(true)



  }

  function Promocao(item: Cupom) {

    const date = moment(item.timezoneMotorista).format("YYYY-MM-DD")
    const timezoneMotorista:string   = NomeMes(date)

    return (
      <IonItem onClick={() => verCupom(item)} detail>
        <IonNote style={{ fontSize: 25, paddingRight: 10, marginTop:20 }}>{item.porcentagem}%</IonNote>
        <IonLabel>
          <h2>{item.nomeServico}</h2>
          <h3>{item.nomeParceiro}</h3>
          <p>Reservado {timezoneMotorista}</p>
        </IonLabel>
      </IonItem>
    )
  }

  function whatsAppParceiro(telefone:string){
      window.open(`https://wa.me/55${telefone}?text=Ol%C3%A1!+Te+achei+no+App+S%C3%B3Ganhei`, '_system')
  }


  return (

    <IonPage>
      <Menu route={props} nomeMenu="Meus Cupons" />
      <IonContent>
        <IonSearchbar value={searchText} placeholder="Buscar serviços" onIonChange={(e) => buscarCupom(e.detail.value!)}></IonSearchbar>
        <IonModal isOpen={showModal}>

          <IonHeader>
            <IonToolbar className="ionToolbarModal">
              <IonTitle>Cupom</IonTitle>
              <IonButtons slot="end" >
                <IonButton color="light" onClick={() => setShowModal(false)}>Fechar</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
             <IonItem lines="full">
                <IonLabel>Número</IonLabel>
                <IonLabel>{bodyCupom?.idCupom}</IonLabel>
              </IonItem>
              <IonItem lines="full">
                <IonLabel>Reservado</IonLabel>
                <IonLabel>{bodyCupom?.timezoneMotorista}</IonLabel>
              </IonItem> 
              <IonItem lines="full">
                <IonLabel>Valor do serviço</IonLabel>
                <IonLabel>R$ {toMoney(`${bodyCupom?.valorServico}`)}</IonLabel>
              </IonItem>
              <IonItem lines="full">
                <IonLabel>Valor com desconto</IonLabel>
                <IonLabel>R$ {toMoney(`${bodyCupom?.valorComDesconto}`)}</IonLabel>
              </IonItem>
              <IonItem lines="full">
                <IonLabel>Serviço</IonLabel>
                <IonLabel>{bodyCupom?.nomeServico}</IonLabel>
              </IonItem>
              <IonItem lines="full">                 
                <IonLabel> {bodyCupom?.nomeParceiro}  </IonLabel>
              </IonItem>
              <IonItem lines="full">    
                      
                <IonLabel>
                    {bodyCupom?.endereco.rua} , número {bodyCupom?.endereco.numero} <br/> {bodyCupom?.endereco.bairro} {bodyCupom?.endereco.cidade} {bodyCupom?.endereco.uf}
                    <br/> {bodyCupom?.endereco.cep} 
                </IonLabel>
              </IonItem>
              
              <IonButton className="btnAgendarHorario" style={{marginTop:20}} onClick={() => whatsAppParceiro(`${bodyCupom?.whatsAppParceiro}`) } expand="full">AGENDAR HORÁRIO</IonButton>
          </IonContent>


        </IonModal>
        <IonList>
          {items.map((data, idx) => <Promocao {...data} key={idx} />)}
        </IonList>
      </IonContent>
    </IonPage>

  );
};

export default CuponsPage;