import {IonContent, IonPage, IonButton} from '@ionic/react';
  
  import React from 'react';
  import { RouteComponentProps } from 'react-router-dom'
  
  import Menu from '../components/Menu'
  
  
  import "./Ajuda.css"
   
  const AjudaPage: React.FC<RouteComponentProps> = (props) => {
    
  
    function whatsAppParceiro(telefone:string){
        window.open(`https://wa.me/${telefone}?text=Ol%C3%A1!+Te+achei+no+App+S%C3%B3Ganhei`, '_system')
    }
  
  
    return (
  
      <IonPage>
        <Menu route={props} nomeMenu="Ajuda" />
        <IonContent>
           
        <IonButton className="btnContatoWhatsApp" style={{marginTop:20}} onClick={() => whatsAppParceiro("5531989154218") } expand="full">Contato via WhatsApp</IonButton>
   
          
        </IonContent>
      </IonPage>
  
    );
  };
  
  export default AjudaPage;
  