import React from 'react';

import {
  
  IonIcon,  
  IonRouterOutlet,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonHeader,
  
} from '@ionic/react';

import {RouteComponentProps } from 'react-router-dom'

import './Menu.css'

import { menuController } from '@ionic/core';

import {  personCircle, ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';

type Menu = {
   route:RouteComponentProps, 
   nomeMenu: string
} 

const Tabs: React.FC<Menu> = ({route,nomeMenu}) => {

  function meuPerfil(){
       route.history.push("/perfil")
  }
 
  return (

    <>
        
        <IonRouterOutlet id="first"></IonRouterOutlet>
        <IonMenu side="start" contentId="first">
          <IonHeader>
          <IonToolbar className="tabBarMenu">
            <IonTitle color="light">Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
          <IonContent>
            <IonList>
              <IonItem onClick={()=> route.history.push("/home") }>Descontos</IonItem>
              <IonItem onClick={()=> route.history.push("/cupons") }>Meus Cupons</IonItem>
              <IonItem onClick={()=> route.history.push("/indicar") }>Indicar Parceiro</IonItem>
              <IonItem onClick={()=> route.history.push("/ajuda") }>Ajuda</IonItem>
              <IonItem onClick={()=> route.history.push("/login") }>Sair</IonItem>
               
            </IonList>
          </IonContent>
      </IonMenu>
      
      <IonToolbar className="tabBarMenu">
        <IonButtons slot="secondary"  onClick={()=> meuPerfil() }>
          <IonButton color="light">
            <IonIcon slot="icon-only" icon={personCircle}  />
          </IonButton>
        </IonButtons>
        <IonButtons slot="primary" onClick={()=> menuController.open() }>
          <IonButton color="light">
            <IonIcon slot="icon-only"  ios={ellipsisHorizontal} md={ellipsisVertical} />
          </IonButton>
        </IonButtons>
        <IonTitle color="light">{nomeMenu}</IonTitle>
      </IonToolbar>
      
    </> 
);

}

export default Tabs