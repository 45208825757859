import { 
  IonContent, 
  IonInput, 
  IonItem, 
  IonLabel, 
  IonPage, 
  IonButton, 
  IonLoading, 
  IonAlert,   
  } from '@ionic/react';
 
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'

import { Plugins } from '@capacitor/core';

import http from '../http'


import './Login.css';
 
 

const Login: React.FC<RouteComponentProps> = ({ history }) => {

  const [email, setEmail] = useState("")
  const [senha, setSenha] = useState("")
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { Storage } = Plugins;

   async function setStorage(token:string){
      await Storage.set({
        key: 'soganheiTokenApp',
        value: token, 
      });
   }
  
  function login(){
    
    setShowLoading(true)

    const payload:Object ={
      email, 
      senha, 
    }

    const config = {
      headers:{
        "Content-Type":"application/json",
        "X-App":"soganhei",
      }
    }
 
    http.post("/auth/motorista",payload,config).then(response=>{

      const {token, pagina} = response.data
     
      setShowLoading(false)        

      setStorage(token)
      history.push(`/home`)


    }).catch(error=>{

      setShowLoading(false)        
      setShowAlert(true)
      
    })


  }
 
  useEffect(() => {
    http.get("/auth/ping")
  }, [])

  return (

    <IonPage>
      <IonContent className="login">
       
        
        <div id="form">
          <div style={{textAlign:"center"}}>
              <img src="https://soganhei.com.br/images/logo.png" id="logo" alt="Logo SóGanhei"/> 
          </div>
          <form action="" name="formInputs" style={{marginTop:20}}>
            <IonItem className="itemInput" lines="full">
              <IonLabel position="floating">email</IonLabel>
              <IonInput value={email} type="email" onIonChange={e => setEmail(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem className="itemInput itemSenha" lines="full">
              <IonLabel position="floating">senha</IonLabel>
              <IonInput value={senha} type="password" onIonChange={e => setSenha(e.detail.value!)}></IonInput>
            </IonItem>
            <IonButton className="btnSuccess" expand="block" style={{ marginTop: 30 }} onClick={() => login()}>Login</IonButton>
          </form>
          <br />
          <IonButton className="btnPrimary" expand="block" onClick={() => history.push("/novo")}>Novo Motorista</IonButton>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => {}}
            message={'Aguarde...'} 
          />
           <IonAlert
          isOpen={showAlert}
          onDidDismiss={(e) => setShowAlert(false) }         
          header={ "Email ou senha inválida!" }           
          message={``}
          buttons={["OK"]}
        />

        </div>

      </IonContent>
    </IonPage>

  );
};

export default Login;
